import React from 'react';
import './Banner.scss';

const Banner = () => {
  return (
    <div className='banner'>
      <p className='banner__text'>
        WEDNESDAY TO SUNDAY 18 - LATE . WEDNESDAY TO SUNDAY 18 - LATE .
        WEDNESDAY TO SUNDAY 18 - LATE . WEDNESDAY TO SUNDAY 18 - LATE .
        WEDNESDAY TO SUNDAY 18 - LATE . WEDNESDAY TO SUNDAY 18 - LATE .
        WEDNESDAY TO SUNDAY 18 - LATE . WEDNESDAY TO SUNDAY 18 - LATE .
      </p>
    </div>
  );
};

export default Banner;
